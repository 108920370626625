<template>
    <div>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        <CRow>
                            <CCol md="12">
                                AddModule
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
                        <CCardBody>
                            <div class="form-group">
                                <label for=""><b>Nama Pelabuhan <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Nama Pelabuhan" v-model="pl_nama">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Kode Nama Pelabuhan <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Kode Nama Pelabuhan" v-model="pl_code_nama">
                            </div>
                            <div class="form-group">
                                <label for=""><b>Kode Pelabuhan <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Kode Pelabuhan" v-model="pl_code">
                            </div>
                            
                            <div class="form-group">
                                <label for=""><b>Lokasi Pelabuhan <span class="text-danger">*</span></b></label>
                                <input type="text" class="form-control" placeholder="Lokasi Pelabuhan" v-model="pl_lokasi">
                            </div>
                        </CCardBody>
                        <CCardFooter>
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
                            </div>
                        </CCardFooter>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
    const null_array = [];
    export default {
        name: "AddJenisTanaman",
        components: {
        },
        data() {
            return {
                pl_nama: '',
                pl_code_nama: '',
                pl_code: '',
                pl_lokasi: '',
                pl_ncode: null,
                pl_ps_code: null,
                idPelabuhan: this.$route.params.idPelabuhan
            }
        },
        methods: {
            storeProcess: function () {
                let self = this;
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Loading...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
                axios.put(
                    this.apiLink + "api/crud/pelabuhan",
                    {
                        id: this.idPelabuhan,
                        pl_nama: this.pl_nama,
                        pl_code_nama: this.pl_code_nama,
                        pl_code: this.pl_code,
                        pl_lokasi: this.pl_lokasi,
                        pl_ncode: this.pl_ncode,
                        pl_ps_code: this.pl_ps_code,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Anda akan diarahkan ke halaman master pelabuhan",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.$router.push({name: 'AllPelabuhan'});
                            }
                        }).catch((error) => {
                            var error_res = error.response.data.meta;
                            if (error_res.code != 200) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: error_res.message
                                });
                            }
                        });
                    }
                });
            },
        },
        created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            axios.get(this.apiLink + "api/crud/pelabuhan", {
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
                params: {
                    id: this.idPelabuhan, 
                },
            })
            .then((response) => {
                var res_module = response.data;
                var res_module_data = res_module.data[0];
                if (res_module.meta.code != "200") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res_module.data.message,
                    });
                } else {
                    Swal.close();
                    this.pl_nama = res_module_data.data[0].pl_nama;
                    this.pl_code_nama = res_module_data.data[0].pl_code_nama;
                    this.pl_code = res_module_data.data[0].pl_code;
                    this.pl_lokasi = res_module_data.data[0].pl_lokasi;
                    this.pl_ncode = res_module_data.data[0].pl_ncode;
                    this.pl_ps_code = res_module_data.data[0].pl_ps_code;
                }
            });

        }
    };
</script>